
<template>
  <div>
    <div>

      <div class="main_background_p">
        <p id="punish_p">处罚清单</p>
        <p>全量清单，聚焦场景</p>
        <div  style="display: flex;align-items: center;">
          <el-select @change="changeBankType" v-model="bankType" style="margin-left: 1rem;width: 0.6rem;border-radius: 5px;border: 1px solid #2696FF;">
            <el-option key="1" label="金监局" value="1"></el-option>
            <el-option key="2" label="人民银行" value="2"></el-option>
          </el-select>
          <el-select v-model="queryType" placeholder="模糊查询" style="width: 0.6rem;border-radius: 5px;border: 1px solid #2696FF;margin-left: 10px">
            <el-option key="1" label="模糊查询" value="1"></el-option>
            <el-option key="2" label="精准查询" value="2"></el-option>
          </el-select>
          <el-input v-model="name"  placeholder="文号/处罚对象/处罚所属公司/处罚事由/违法事实" style="width: 2.14rem;margin-left: 10px;border-radius: 5px 0px 0px 5px;border: 1px solid #2696FF;margin-left: 10px"></el-input>
          <el-button @click="queryPunish" icon="el-icon-search"  style="border-radius: 0px 5px 5px 0px;width: 0.46875rem;background-color: #2696FFFF;color: #FFFFFF;margin-left: -1px;height: 0.19rem;display: flex;text-align: center;align-items: center">查询</el-button>
          <el-button type="primary" id="backButton" @click="filtrate">
            <span style="display: flex;align-items: center"><img id="gaojishaixuan" style="width: 14px; height: 14px;" src="@/assets/image/icon/shaixuan.png" alt=""/>
            &nbsp;&nbsp;高级筛选</span>
          </el-button>

          <el-dropdown style="align-items: center">
            <span class="el-dropdown-link">
               <el-button type="primary">
                 <span style="display: flex;align-items: center"><img id="gaojishaixuan" style="width: 14px; height: 14px;" src="@/assets/image/icon/dingzhi.png" alt=""/>
            &nbsp;&nbsp;    我的定制</span>
               </el-button>
            </span>
            <el-dropdown-menu style="width: 120px"  >
              <el-dropdown-item :disabled="dzFlag1" @click.native='handleCommand'>可视化图表<img v-show="dzFlag1" id="gaojishaixuan" style="width: 12px; height: 12px;" src="@/assets/image/icon/jy.png" alt=""/></el-dropdown-item>
              <el-dropdown-item @click.native="handlePunish" :disabled="dzFlag2">处罚监测 <img v-show="dzFlag2"  id="gaojishaixuan" style="width: 12px; height: 12px;" src="@/assets/image/icon/jy.png" alt=""/></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </div>
      </div>
      <!-- 欢迎使用 -->
      <div v-show="gjQuery" class="senior_page main_background">
        <div style="margin-top: 20px;">
          <el-form :model="ruleForm" label-width="100px"  label-position="right" >

            <el-row>
              <el-col :span="12">
                <el-form-item label="处罚时间" style="margin-left: 1.15rem">
                  <el-radio-group v-model="radioP" style="padding-left: 0px;padding-right: 0px">
                    <el-radio :label="1">本月</el-radio>
                    <el-radio :label="2">本季度</el-radio>
                    <el-radio :label="3">本年</el-radio>
                    <el-radio :label="4">自定义</el-radio>
                  </el-radio-group>
                  <el-date-picker
                          unlink-panels
                          v-model="dateP"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始时间"
                          end-placeholder="结束时间"
                          value-format="yyyy-MM-dd"
                          v-show="this.radioP == 4"
                          :clearable="false"
                          style="height: 0.16666666rem ;width: 1.25rem;margin-left: 5px;"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="监管发布时间" style="margin-left: 35px">
                  <el-radio-group v-model="radioF" style="padding-left: 0px;padding-right: 0px">
                    <el-radio :label="1">本月</el-radio>
                    <el-radio :label="2">本季度</el-radio>
                    <el-radio :label="3">本年</el-radio>
                    <el-radio :label="4">自定义</el-radio>
                  </el-radio-group>
                  <el-date-picker
                      unlink-panels
                      v-model="dateF"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      value-format="yyyy-MM-dd"
                      v-show="this.radioF == 4"
                      :clearable="false"
                      style="height: 0.16666666rem ;width: 1.25rem;margin-left: 5px;"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">

                <el-form-item label="处罚对象类别"  style="margin-top: 8px;margin-left: 1.15rem" >
                  <el-select
                      v-model="punObject"
                      multiple
                      collapse-tags
                      style="width: 2.71rem"
                      placeholder="请选择">
                    <el-option
                        v-for="item in objectOptions"
                        :key="item.punObject"
                        :label="item.punObject"
                        :value="item.punObject">
                    </el-option>
                  </el-select>
                </el-form-item>

              </el-col>
              <el-col :span="12">
                <el-form-item label="处罚金额"  style="margin-top: 8px;margin-left: 35px" >
                  <el-input type="input"  placeholder="请输入"   style="width: 1.25rem"  v-model="punMoneyStart">
                    <template #suffix>
                      <span style="color: #606266;">
                        万元
                      </span>
                    </template>
                  </el-input>
                  <span style="margin-left: 0.052083333rem">
                    —
                  </span>
                  <el-input type="input"  placeholder="请输入"   style="margin-left: 0.052083333rem;width: 1.25rem;"  v-model="punMoneyEnd">
                    <template #suffix>
                      <span style="color: #606266;">
                        万元
                      </span>
                    </template>
                  </el-input>
                </el-form-item>

              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="兼业代理类型"  style="margin-top: 8px;margin-left: 1.15rem" >
                  <el-select
                      v-model="punJyType"
                      collapse-tags
                      style="width: 2.71rem"
                      placeholder="请选择">
                    <el-option
                        v-for="item in tagJyList"
                        :key="item.tagDetId"
                        :label="item.detName"
                        :value="item.tagDetId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="其他处罚"  style="margin-top: 8px;margin-left: 35px" >
                  <el-select
                          v-model="punOther"
                          multiple
                          collapse-tags
                          style="width: 2.71rem"
                          placeholder="请选择">
                    <el-option
                            v-for="item in otherOptions"
                            :key="item.value"
                            :label="item.value"
                            :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="处罚对象名称"  style="margin-top: 8px;margin-left: 1.15rem">
                  <el-input type="input"  placeholder="请输入处罚对象名称"   style="width: 2.71rem"  v-model="punName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :label="nape.name"
                        style="margin-top: 8px;margin-left: 35px"
                        v-for="(nape, index) in punTags"
                        :key="nape.tagId"
                >
                  <el-select
                          v-model="nape.checkedLine"
                          multiple
                          collapse-tags
                          style="width: 2.71rem"
                          placeholder="请选择">
                    <el-option
                            v-for="item in nape.tagDetList"
                            :key="item.tagDetId"
                            :label="item.detName"
                            :value="item.tagDetId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="处罚主体"  style="margin-top: 8px;margin-left: 1.15rem">
                  <el-input type="input" class="el-input-coler" readonly="true" @click.native="morePunSub"  style="width: 2.71rem"  v-model="punSubZK">
                    <template #suffix>
                      <img style="padding-right: 10px;padding-top: 0.0721rem" v-if="!zhutiFlag" src="@/assets/image/icon/jiahao.png">
                      <img style="padding-right: 10px;padding-top: 0.0721rem" v-if="zhutiFlag" src="@/assets/image/icon/jianhao.png">
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="处罚事由"  style="margin-top: 8px;margin-left: 35px">
                  <el-input type="input" class="el-input-coler"  readonly="true" @click.native="morePunReason"   style="width: 2.71rem"  v-model="punReasonZK">
                    <template #suffix>
                      <img style="padding-right: 10px;padding-top: 0.0721rem" v-if="!shiyouFlag" src="@/assets/image/icon/jiahao.png">
                      <img style="padding-right: 10px;padding-top: 0.0721rem" v-if="shiyouFlag" src="@/assets/image/icon/jianhao.png">
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="" class="backlabel" v-if="zhutiFlag">
              <div style="width: 90%;display: flex;margin-left: 0.785416666rem">
                <el-checkbox v-model="cityCheckAll" style="margin-right: 0.1rem" @change="cityCheckAllChange">全部</el-checkbox>
                <div style="display: inline-block; width: 80%;">
                  <div style="margin-left: 17px" v-for="(city, index) in cities" :key="index">
                    <div v-for="(item, key) in city" :label="key" :key="key" style="display: inline-block;width: 18%; margin-right: 0;">
                      <div style="display: flex; justify-content: space-between;align-items: center;">
                        <div><el-checkbox v-model="cityStatus[key]" @change="(e) => firstCitiesChange(e, key)">{{key}}</el-checkbox></div>
                        <div style="padding-right: 10px;" @click="showMoreCitys(key)">
                          <img src="@/assets/image/icon/xia.png"  v-show="whichCity != key" style="cursor: pointer;">
                          <img src="@/assets/image/icon/shang.png" v-show="whichCity == key" style="cursor: pointer;">
                        </div>
                        <img src="@/assets/image/icon/shangsj.png"  v-show="whichCity == key" style="cursor: pointer;position: absolute;margin-left: 0.15rem;margin-top: 0.18229167rem">
                      </div>
                    </div>
                    <div style="background: #F3F9FF;border: 0px solid #CEE2F7;margin-left: -17px;padding-left: 17px" v-for="(item2, key2) in city" :key="item2.id">
                      <div v-show="whichCity == key2" style="padding-left: 0px ;">
                        <div v-for="item3 in item2" :label="item3.name" :key="item3.id" style="display: inline-block;width: 18%;">
                          <el-checkbox @change="(e) => secondCitiesChange(e, item3, key2)" v-model="item3.checked">{{item3.name}}</el-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </el-form-item>

            <el-form-item label="" class="backlabel" v-if="shiyouFlag">
              <div style="width: 90%;display: flex;margin-left: 0.785416666rem">
                <el-checkbox v-model="cityCheckAll1" style="margin-right: 0.1rem" @change="cityCheckAllChange1">全部</el-checkbox>
                <div style="display: inline-block; width: 80%;">
                  <div style="margin-left: 17px" v-for="(city, index) in cities1" :key="index">
                    <div v-for="(item, key) in city" :label="key" :key="key" style="display: inline-block;width: 30%; margin-right: 0;">
                      <div style="display: flex; justify-content: space-between;align-items: center;">
                        <div><el-checkbox v-model="cityStatus1[key]" @change="(e) => firstCitiesChange1(e, key)">{{key}}</el-checkbox></div>
                        <div style="padding-right: 10px;" @click="showMoreReason(key)">
                          <img src="@/assets/image/icon/xia.png"  v-show="whichCity1 != key" style="cursor: pointer;">
                          <img src="@/assets/image/icon/shang.png" v-show="whichCity1 == key" style="cursor: pointer;">
                        </div>
                        <img src="@/assets/image/icon/shangsj.png"  v-show="whichCity1 == key" style="cursor: pointer;position: absolute;margin-left: 0.25rem;margin-top: 0.18229167rem">
                      </div>

                    </div>
                    <div style="background: #F3F9FF;border: 0px solid #CEE2F7;margin-left: -17px;padding-left: 17px" v-for="(item2, key2) in city" :key="item2.id">
                      <div v-show="whichCity1 == key2" style="padding: 0px;">
                        <div v-for="item3 in item2" :label="item3.punReason" :key="item3.id" style="display: inline-block;width: 30%;">
                          <el-checkbox @change="(e) => secondCitiesChange1(e, item3, key2)" v-model="item3.checked">{{item3.punReason}}</el-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </el-form-item>



            <el-form-item style="margin-top: 26px;margin-bottom: 30px">
              <div @click="gjShou" style="display: inline-block;margin-left: 1.16rem;cursor: pointer;">
                <span style="color: #2696FF;">收起筛选条件</span>
                <img id="gaojishaixuan" style="margin-left: 5px;width: 0.06rem; height: 0.06rem;" src="@/assets/image/icon/shangla.png" alt=""/>
              </div>
              <el-button @click="queryPunish" style="margin-left: 2rem;width: 0.46875rem;height:0.1666666rem;background-color: #2696FFFF;color: #FFFFFF;border: 1px solid #2696FF;display: inline-block;line-height: 0px;">查询</el-button>
              <el-button @click="reset" style="width: 0.46875rem;height:0.1666666rem;border: 1px solid #2696FF;font-weight: bolder;color: #2696FF;display: inline-block;line-height: 0px;">重置</el-button>
            </el-form-item>
          </el-form>

        </div>
      </div>
      <div class="senior_punish_detail_box">
        <!-- 列表 -->
        <div class="punish_senior_detail">
          <div class="punish_detail_header">
            <img src="@/assets/image/icon/bianzu.png" alt="" />
            <span>处罚清单列表</span>
            <el-button @click="exportQueryPunish" style="margin-top: 0.07rem;margin-left: 7.4125rem;width: 0.46875rem;height:0.1666666rem;background-color: #2696FFFF;color: #FFFFFF;border: 1px solid #2696FF;display: inline-block;line-height: 0px;">导出</el-button>
          </div>

          <el-table
                  :data="tableDataH"
                  :header-cell-style="{ 'text-align':'center',background:'#EFF4F9',color:'rgba(0,0,0,0.85)'}"
                  :highlight-current-row="true"
                  :row-style="tabRowClassName"
                  :span-method="objectSpanMethod"
                  border
                  style="width: 100%;margin-top: 10px;">

            <template slot="empty" v-if="initPunish">
              <img src="@/assets/image/punish/initPunish.png" alt style="margin: 0px auto 0px auto; display: block;" />
              <p style="line-height: 22px; font-size: 14px; margin-bottom: 60px">请您在上方输入查询条件，即可搜索对应处罚</p>
            </template>
            <template slot="empty" v-else>
              <img src="@/assets/image/punish/noData.png" alt style="margin: 0px auto 0px auto; display: block" />
              <p style="line-height: 22px; font-size: 14px; margin-bottom: 60px">查询结果为空，请重新录入查询条件</p>
            </template>


            <el-table-column prop="num" label="序号" width="60"  align="center"></el-table-column>

            <el-table-column prop="fileNum" label="处罚文书号"  >
              <template slot-scope="scope">
                <div @click="goArticle(scope.row.punId)" style="color: #2696FF;cursor: pointer;">{{ scope.row.fileNum }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="subName"  label="处罚主体" width="100"></el-table-column>
            <el-table-column prop="punTime" label="处罚时间" align="center" width="100"  ></el-table-column>
            <el-table-column prop="govPubTime" label="发布时间" align="center" width="100"  ></el-table-column>
            <el-table-column prop="punObject" label="处罚对象类别"  width="120" ></el-table-column>
            <el-table-column prop="punName" label="处罚对象"  ></el-table-column>
            <el-table-column prop="belongCompany" label="所属公司" ></el-table-column>
            <el-table-column prop="identity" label="职务" ></el-table-column>
            <el-table-column prop="identityCategory" label="职务类别" ></el-table-column>
            <el-table-column prop="punMoney" label="处罚金额(万元)" align="center"  width="115"></el-table-column>
            <el-table-column prop="mergeState" label="是否合并处罚" align="center"   width="105" :formatter="mergeStateFormat" ></el-table-column>
            <el-table-column prop="punOthor" label="其他处罚"  align="center"  width="110">

            </el-table-column>
            <el-table-column prop="illegalFacts" label="违法事实" width="220">
              <template slot-scope="scope">
                <div class="text-ellipsis-2" >
                  <el-tooltip class="item" open-delay="700"  effect="light" :content="scope.row.illegalFacts" placement="top-start">
                    <div>
                      {{ scope.row.illegalFacts }}
                    </div>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="punReason" label="处罚事由" width="120">
              <template slot-scope="scope">
                <div class="text-ellipsis-2" >
                  <el-tooltip class="item" open-delay="700" effect="light" :content="scope.row.punReason" placement="top-start">
                    <div>
                      {{ scope.row.punReason }}
                    </div>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
     <!--       <el-table-column prop="punCredential" label="处罚依据" width="190" show-overflow-tooltip></el-table-column>-->
<!--            <el-table-column label="处罚结果"  align="center">

            </el-table-column>-->
      <!--      <el-table-column prop="annotation" label="备注" width="150" show-overflow-tooltip></el-table-column>-->
          </el-table>

          <!-- 分页 -->
          <div style="margin-top: 20px;padding-bottom: 20px" v-if="initPage">
            <el-pagination
                    @size-change="handleCurrentChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10]"
                    :page-size="10"
                    layout="prev,pager,next,sizes,jumper"
                    :total="total"
                    :pager-count="9"
                    background>
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 模块配置对话框 -->
    <el-dialog title="处罚监测" :visible.sync="openPunish" :close-on-click-modal="false"  width="810px" append-to-body>
      <el-form ref="form" :model="ruleForm" label-position="right" label-width="100px">
        <el-form-item label="监测期间:"  :required="true" style="margin-left: 20px">
          <el-date-picker
              unlink-panels
              v-model="dateP"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd"
              :clearable="false"
              style="height: 0.16666666rem ;"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="监测对象:" v-if="!openMonitor" style="margin-left: 20px;margin-top: 15px;"  :required="true" >
          <el-input :rows="5"  type="textarea"  placeholder="请输入处罚对象名称, 换行输入不同名称"  style="width: 520px;margin-top: 5px;margin-bottom: 30px"  v-model="desc"></el-input>
        </el-form-item>

        <el-form-item v-else label="监测对象:"  style="margin-left: 20px;margin-top: 15px;"  :required="true" >
          <el-input :rows="5"  type="textarea"  placeholder="请输入处罚对象名称, 换行输入不同名称"  style="width: 520px;margin-top: 5px;margin-bottom: 15px"  v-model="desc"></el-input>
        </el-form-item>

        <div v-if="openMonitor" style="align-items: center">
          <img style="margin-left: 124px;display: inline-block;" src="@/assets/image/icon/fill.png" alt="" />
          <p style="margin-left: 10px;display: inline-block;font-size: 14px;font-weight: bold;color: #333333;">查询结果</p>
        </div>
        <div v-if="openMonitor" style="margin-bottom: 12px">
          <p style="margin-left: 148px;display: inline-block;font-size: 14px;font-weight: 400;color: #333333;">
            本监测期间处罚清单<span style="font-size: 18px;font-weight: bold;">{{this.size1}}</span>条,本年度处罚清单<span style="font-size: 18px;font-weight: bold;">{{this.size2}}</span>条,近一年处罚清单<span style="font-size: 18px;font-weight: bold;">{{this.size3}}</span>条.
          </p>
          <img style="margin-left: 5px;" src="@/assets/image/icon/export.png" alt="" />
          <p style="margin-left: 5px;display: inline-block;color: #2696FF;"  @click="handleExportJC">导出数据</p>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer"  style="text-align: center">
        <el-button @click="cancelPunish" style="width: 0.46875rem;height:0.1666666rem;border: 1px solid #2696FF;font-weight: bolder;color: #2696FF;display: inline-block;line-height: 0px;">取 消</el-button>
        <el-button @click="handleMonQuery" style="width: 0.46875rem;height:0.1666666rem;background-color: #2696FFFF;color: #FFFFFF;border: 1px solid #2696FF;display: inline-block;line-height: 0px;">查询</el-button>
      </div>
      <div style="width: 4px;height: 0.09375rem;background: #2696FF;border-radius: 6px;position: absolute;left: 0.10416rem;top: 0.1rem">
      </div>
    </el-dialog>



  </div>
</template>

<script>
import { getDutyListApi, getSeniorPunish, queryPunishTags,exportQueryExcel, exportExcel, querySenior} from "@/api/dutyListApi";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      list: [],
      value: "",
      queryType:"1",
      bankType:"1",
      loading:true,
      name:"",
      year: "",
      yearInfo: [],
      mainCss:true,
      gjQuery: false,
      dzFlag1: true,
      dzFlag2: true,
      //高级筛选下列表
      search_tagList: [],
      //多选
      checkAll: [],
      checkedLine: [],
      // 表单校验
      ruleForm: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      //单选
      radioP: "",
      radioF: "",
      dateP: [],
      dateF: [],
      punJyType:'',
      desc: "",
      punName: "",
      punMoneyStart: "",
      punMoneyEnd: "",
      punSubZK: "展开处罚主体",
      punReasonZK: "展开处罚事由",
      tableDataH: [],
      mapData: {},
      more:false,
      punObject: [],
      punOther: [],
      punReason: [],
      reasonOptions: [],
      configList:[],
      punTags: [],
      initPunish:true,
      initPage:false,
      listname: "处罚清单表.xlsx",
      total: 0,
      objectOptions: [
      ],
      objectBankOptions: [
        {punObject: '（个人）其他'},
        {punObject: '人身险公司'},
        {punObject: '（个人）人身险公司'},
        {punObject: '财产险公司'},
        {punObject: '（个人）财产险公司'},
        {punObject: '保险专业代理公司'},
        {punObject: '（个人）保险专业代理公司'},
        {punObject: '保险经纪公司'},
        {punObject: '（个人）保险经纪公司'},
        {punObject: '保险公估公司'},
        {punObject: '（个人）保险公估公司'},
        {punObject: '保险兼业代理公司'},
        {punObject: '（个人）保险兼业代理公司'},
        {punObject: '保险资管公司'},
        {punObject: '（个人）保险资管公司'},
        {punObject: '保险集团公司'},
        {punObject: '（个人）保险集团公司'},
        {punObject: '再保险公司'},
        {punObject: '（个人）再保险公司'},
        {punObject: '其他机构'},
      ],
      otherOptions: [
        {value: '警告'},
        {value: '责令停止接受新业务'},
        {value: '取消、撤销任职资格'},
        {value: '禁止进入保险业'},
        {value: '没收违法所得'},
        {value: '限制业务范围'},
        {value: '责令停业整顿'},
        {value: '吊销业务许可证'},
        {value: '撤销外国保险机构驻华代表机构'},
        {value: '责令撤换外国保险机构驻华代表机构的首席代表'},
        {value: '责令撤换'},
      ],
      shiyouFlag: false,
      whichCity1: '',
      cityStatus1: {},
      cityCheckAll1: false,
      checkedCities1: [],
      cities1: [],
      firstCitys1: [],
      zhutiFlag: false,
      whichCity: '',
      cityStatus: {},
      cityCheckAll: false,
      checkedCities: [],
      cities: [],
      firstCitys: [],
      citieBanks: [],
      citieJinRs: [],
      tagJyList:[],
      reasonBankList:[],
      reasonList:[],
      expFlag:false,
      size1: "",
      size2: "",
      size3: "",
      // 是否显示弹出层(处罚监测导出)
      openPunish: false,
      // 弹出层标题
      title: '处罚监测',
      openMonitor: false,
      listjcname: "处罚监测表.xlsx",
    };
  },
  async mounted() {

    if(localStorage.getItem("tenantId") == 1){
      this.dzFlag1 = false
      this.dzFlag2 = false
    }else if(localStorage.getItem("tenantId") == 11){
      this.dzFlag2 = false
    }else if(localStorage.getItem("tenantId") == 12){
      this.dzFlag1 = false
    }

    queryPunishTags(
    ).then((ret) => {
      console.log(ret)
      this.cities1 = ret.data.data.reasonList;
      this.firstCitys1 = ret.data.data.reasonList;
      this.reasonBankList = ret.data.data.reasonBankList;
      this.reasonList = ret.data.data.reasonList;
      this.citieBanks = ret.data.data.subBankList;

      this.citieJinRs = ret.data.data.subList;
      this.cities = ret.data.data.subList;
      this.firstCitys = ret.data.data.subList;
      this.expFlag = ret.data.data.expFlag;
      this.punTags = ret.data.data.tagList;
      this.configList = ret.data.data.configList;
      this.objectOptions = ret.data.data.configList;
      this.tagJyList = ret.data.data.tagJyList
      ret.data.data.reasonList.map(item => {
        for (let key in item) {
          this.cityStatus1[key] = false
        }
      })
      ret.data.data.subList.map(item => {
        for (let key in item) {
          this.cityStatus[key] = false
        }
      })
      this.punTags.forEach(
              (item)  =>{
                this.$set(item, "checkedLine", []);
              }
      )
    });
  },

  methods: {

    changeBankType(){
      this.reset()
      if(this.bankType == "1"){
        //金融监管局
        this.cities = this.citieJinRs;
        this.firstCitys = this.citieJinRs;
        this.objectOptions = this.configList;
        this.cities1 = this.reasonList;
        this.firstCitys1 = this.reasonList;
      }
      if(this.bankType == "2"){
       //人民银行
        this.cities = this.citieBanks;
        this.firstCitys = this.citieBanks;
        this.objectOptions = this.objectBankOptions;
        this.cities1 = this.reasonBankList;
        this.firstCitys1 = this.reasonBankList;
      }

    },
    //处罚详情
    goArticle(id) {

      if(this.bankType == 1){
        let text = this.$router.resolve({ name: "article", query: { id } });
        window.open(text.href, '_blank')
      }else{
        let text = this.$router.resolve({ name: "weeklyBankDetail", query: {
            id : id ,
            key:this.$route.query.key,
            date:this.$route.query.date,
            userId: this.$route.query.UserId,
            groupId: this.groupId,
          } });
        window.open(text.href, '_blank')
      }


    },
    /** 导出按钮操作 */
    handleExport() {

      this.$confirm('是否确认导出处罚清单数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.exportPunish();
      })
    },
    // 取消按钮（处罚监测）
    cancelPunish() {
      this.openPunish = false
      this.dateP = [];
      this.desc = "";
    },
    /** 导出按钮操作 */
    handlePunish(item) {
      this.dateP = [];
      this.desc = "";
      this.openMonitor = false;
      this.openPunish = true;
    },
    /** 处罚监测查询操作 */
    handleMonQuery() {
      if(this.dateP == undefined ||this.dateP ==null|| this.dateP.length <=0){
        this.$notify({
          title: '警告',
          message: '请选择监测期间进行查询！',
          type: 'error',
          duration: 3000
        });
        return;
      }

      var nameList;
      if(this.desc != ""){
        nameList = this.desc.split("\n");
        nameList = nameList.filter(item =>{
          if(item != ''){
            return item
          }
        })
      }

      if(this.desc == "" || nameList.length  == 0){
        this.$notify({
          title: '警告',
          message: '请输入监测对象进行查询！',
          type: 'error',
          duration: 3000
        });
        return;
      }
      this.querySeniorPunish();
    },
    //查询汇总数据
    querySeniorPunish(){
      var nameList;
      if(this.desc != ""){
        nameList = this.desc.split("\n");
      }
      nameList = nameList.filter(item =>{
        if(item != ''){
          return item
        }
      })

      var detlist = [];
      [].concat.apply([],this.checkedLine).map((value) =>{
        if(!!value){detlist.push(value)}
      })
      querySenior({
            punTimeBeg: this.dateP[0], //处罚时间开始
            punTimeEnd: this.dateP[1], //处罚时间结束
            nameList: nameList,
          }
      ).then((ret) => {
        this.size1 = ret.data.data.size1;
        this.size2 = ret.data.data.size2;
        this.size3 = ret.data.data.size3;
        this.openMonitor = true;
      });
    },
    /** 导出按钮操作 */
    handleExportJC(item) {
      if(this.dateP == undefined ||this.dateP ==null|| this.dateP.length <=0){
        this.$notify({
          title: '警告',
          message: '请选择监测期间进行导出！',
          type: 'error',
          duration: 3000
        });
        return;
      }
      var nameList;
      if(this.desc != ""){
        nameList = this.desc.split("\n");
        nameList = nameList.filter(item =>{
          if(item != ''){
            return item
          }
        })
      }
      if(this.desc == "" || nameList.length == 0){
        this.$notify({
          title: '警告',
          message: '请输入监测对象进行导出！',
          type: 'error',
          duration: 3000
        });
        return;
      }
      this.$confirm('是否确认导出处罚清单数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.exportPunish(item);
        this.cancelPunish();
      })
    },
    //导出数据
    exportPunish(item){
      var nameList;
      if(this.desc != ""){
        nameList = this.desc.split("\n");
      }

      nameList = nameList.filter(item =>{
        if(item != ''){
          return item
        }
      })

      this.year = this.value;
      var detlist = [];
      [].concat.apply([],this.checkedLine).map((value) =>{
        if(!!value){detlist.push(value)}
      })
      exportExcel({
            type: this.type,
            yearType: this.year == "最新" ? "" : this.year,
            tagDetIdList: detlist,
            punTimeType: this.radioP, //处罚时间类型 字典值PUN_TIME_TYPE
            punTimeBeg: this.dateP[0], //处罚时间开始
            punTimeEnd: this.dateP[1], //处罚时间结束
            nameList: nameList,
          },{ responseType: "blob" }
      ).then((ret) => {
        let blob = new Blob([ret.data], { type: "application/vnd.ms-excel" });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        // downloadElement.download = `用户手册-${this.name}`    //下载后的文件名，根据需求定义
        downloadElement.download = this.listjcname; //下载后的文件名，根据需求定义
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
      });
    },
    //导出数据
    exportQueryPunish(){

      //const loadingInstance = Loading.service({ lock: true, text: '正在导出数据中，请耐心等待', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.4)'})
      if(!this.expFlag){
        this.$notify({
          title: '警告',
          message: '没有导出权限，请联系管理员！',
          type: 'error',
          duration: 3000
        });
        return;
      }
      const loadingInstance = Loading.service({text: '正在导出数据中，请耐心等待', fullscreen: true });
      var detlist = [];
      this.punTags.forEach((item) =>{
        detlist.push(...item.checkedLine)
      })
      let that = this;
      exportQueryExcel({
             punTimeType: that.radioP, //处罚时间类型 字典值PUN_TIME_TYPE
             punTimeBeg: that.dateP[0], //处罚时间开始
             punTimeEnd: that.dateP[1], //处罚时间结束
             govPubTimeType: that.radioF, //发布时间类型 字典值PUN_TIME_TYPE
             govPubTimeBeg: that.dateF[0], //发布时间开始
             govPubTimeEnd: that.dateF[1], //发布时间结束
             punJyType: that.punJyType,
             queryType: that.queryType,
             bankType: that.bankType,
             name:that.name,
             punName: that.punName,
             tagDetIdList: detlist,
             punObjectList: that.punObject, //处罚对象类别
             punOtherList: that.punOther, //其他处罚
             illegalFactsList: this.punReason,
             reasonIds: this.filterCityData1(this.cities1),
             subIds: this.filterCityData(this.cities),
             punMoneyStart: that.punMoneyStart,
             punMoneyEnd: that.punMoneyEnd,
          },{ responseType: "blob" }
      ).then((ret) => {
        let blob = new Blob([ret.data], { type: "application/vnd.ms-excel" });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        // downloadElement.download = `用户手册-${this.name}`    //下载后的文件名，根据需求定义
        downloadElement.download = this.listname; //下载后的文件名，根据需求定义
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
        loadingInstance.close();
      });
    },


    async queryPunish() {
      var detlist = [];
      this.punTags.forEach((item) =>{
        detlist.push(...item.checkedLine)
      })
      let that = this;
      let ret = await getSeniorPunish({
        punTimeType: that.radioP, //处罚时间类型 字典值PUN_TIME_TYPE
        punTimeBeg: that.dateP[0], //处罚时间开始
        punTimeEnd: that.dateP[1], //处罚时间结束
        govPubTimeType: that.radioF, //发布时间类型 字典值PUN_TIME_TYPE
        govPubTimeBeg: that.dateF[0], //发布时间开始
        govPubTimeEnd: that.dateF[1], //发布时间结束
        punJyType: that.punJyType,
        queryType: that.queryType,
        bankType: that.bankType,
        name:that.name,
        punName: that.punName,
        tagDetIdList: detlist,
        punObjectList: that.punObject, //处罚对象类别
        punOtherList: that.punOther, //其他处罚
        illegalFactsList: this.punReason,
        reasonIds: this.filterCityData1(this.cities1),
        subIds: this.filterCityData(this.cities),
        punMoneyStart: that.punMoneyStart,
        punMoneyEnd: that.punMoneyEnd,

      });
      if (ret.data.code === 200) {
        this.cities1 = this.firstCitys1;
        this.whichCity1 = '';
        this.cities = this.firstCitys;
        this.whichCity = '';
        that.mapData = {};
        if(ret.data.data.punishList.length == 0){
          this.initPunish = false;
          this.initPage = false;
        }else{
          this.initPage = true;
        }
        this.total = ret.data.data.total;
        that.tableDataH = [];
        ret.data.data.punishList.forEach(function(item, index){
          that.mapData[index + 1] = item.punishInfoList.length;
          item.punishInfoList.forEach(item1 => {
            that.tableDataH.push({
              num: index + 1,
              subName: item.subName,
              id: item.id,
              fileNum: item.fileNum,
              punTime: item.punTime,
              govPubTime: item.govPubTime,
              ...item1
            });
          })
        })
        this.setrowspans();
      }
    },

    async handleCurrentChange(val) {
      var detlist = [];
      this.punTags.forEach((item) =>{
        detlist.push(...item.checkedLine)
      })
      let that = this;
      let ret = await getSeniorPunish({
        punTimeType: that.radioP, //处罚时间类型 字典值PUN_TIME_TYPE
        punTimeBeg: that.dateP[0], //处罚时间开始
        punTimeEnd: that.dateP[1], //处罚时间结束
        govPubTimeType: that.radioF, //发布时间类型 字典值PUN_TIME_TYPE
        govPubTimeBeg: that.dateF[0], //发布时间开始
        govPubTimeEnd: that.dateF[1], //发布时间结束
        punJyType: that.punJyType,
        queryType: that.queryType,
        bankType: that.bankType,
        name:that.name,
        punName: that.punName,
        tagDetIdList: detlist,
        pageNum: val,
        punObjectList: that.punObject, //处罚对象类别
        punOtherList: that.punOther, //其他处罚
        illegalFactsList: this.punReason,
        reasonIds: this.filterCityData1(this.cities1),
        subIds: this.filterCityData(this.cities),
        punMoneyStart: that.punMoneyStart,
        punMoneyEnd: that.punMoneyEnd,
      });
      if (ret.data.code === 200) {
        that.mapData = {};
        that.tableDataH = [];
        ret.data.data.punishList.forEach(function(item, index){
          that.mapData[index + 1] = item.punishInfoList.length;
          item.punishInfoList.forEach(item1 => {
            that.tableDataH.push({
              num: index + 1,
              subName: item.subName,
              id: item.id,
              fileNum: item.fileNum,
              punTime: item.punTime,
              ...item1
            });
          })
        })
      }
    },

    // 是否合并处罚布尔值翻译
    mergeStateFormat(row, column) {
      return row.mergeState ? '是' : '否'
    },
    morePunSub(){
      if(this.zhutiFlag){
        this.zhutiFlag = false
        this.punSubZK = "展开处罚主体"
      }else{
        this.zhutiFlag = true
        this.shiyouFlag = false
        this.punSubZK = "收起处罚主体"
      }
    },
    morePunReason(){
      if(this.shiyouFlag){
        this.shiyouFlag = false
        this.punReasonZK = "展开处罚事由"
      }else{
        this.shiyouFlag = true
        this.zhutiFlag = false
        this.punReasonZK = "收起处罚事由"
      }
    },
    // 用于区分出id相同的值
    setrowspans() {
      console.log(this.tableDataH)
      // 先给所有的数据都加一个v.rowspan = 1
      this.tableDataH.forEach(v => {
        v.rowspan = 1
        v.classStripe = false
      })
      // 双层循环
      for (let i = 0; i < this.tableDataH.length; i++) {
        // 内层循环，上面已经给所有的行都加了v.rowspan = 1
        // 这里进行判断
        // 如果当前行的id和下一行的id相等
        // 就把当前v.rowspan + 1
        // 下一行的v.rowspan - 1
        for (let j = i + 1; j < this.tableDataH.length; j++) {
          if (this.tableDataH[i].fileNum === this.tableDataH[j].fileNum) {
            this.tableDataH[i].rowspan++
            this.tableDataH[j].rowspan--
            this.tableDataH[j].classStripe = this.tableDataH[i].classStripe
          }else{
            this.tableDataH[j].classStripe = !this.tableDataH[i].classStripe
          }
        }
        // 这里跳过已经重复的数据
        i = i + this.tableDataH[i].rowspan - 1
      }
    },
    tabRowClassName({row,rowIndex}){
      let tempObj = {
        background:'#FAFAFA'
      }
      if(row.classStripe){
        return tempObj
      }
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return this.mergeSpanMethod(rowIndex);
      }
      if (columnIndex === 1) {
        return this.mergeSpanMethod(rowIndex);
      }
      if (columnIndex === 2) {
        return this.mergeSpanMethod(rowIndex);
      }

      if (columnIndex === 3) {
        return this.mergeSpanMethod(rowIndex);
      }

      if (columnIndex === 4) {
        return this.mergeSpanMethod(rowIndex);
      }
    },

    //合并单元格统一处理
    mergeSpanMethod(rowIndex) {
      let map = this.mapData;
      if(rowIndex === 0){
        return { rowspan: map[1], colspan: 1 };
      }else if (rowIndex === map[1]){
        return { rowspan: map[2], colspan: 1 };
      }else if (rowIndex === map[1]+map[2]){
        return { rowspan: map[3], colspan: 1 };
      }else if (rowIndex === map[1]+map[2]+map[3]){
        return { rowspan: map[4], colspan: 1 };
      }else if (rowIndex === map[1]+map[2]+map[3]+map[4]){
        return { rowspan: map[5], colspan: 1 };
      }else if (rowIndex === map[1]+map[2]+map[3]+map[4]+map[5]){
        return { rowspan: map[6], colspan: 1 };
      }else if (rowIndex === map[1]+map[2]+map[3]+map[4]+map[5]+map[6]){
        return { rowspan: map[7], colspan: 1 };
      }else if (rowIndex === map[1]+map[2]+map[3]+map[4]+map[5]+map[6]+map[7]){
        return { rowspan: map[8], colspan: 1 };
      }else if (rowIndex === map[1]+map[2]+map[3]+map[4]+map[5]+map[6]+map[7]+map[8]){
        return { rowspan: map[9], colspan: 1 };
      }else if (rowIndex === map[1]+map[2]+map[3]+map[4]+map[5]+map[6]+map[7]+map[8]+map[9]){
        return { rowspan: map[10], colspan: 1 };
      }else{
        return { rowspan: 0, colspan: 0 };
      }
    },
    // 高级筛选展开
    filtrate() {
      if(this.gjQuery){
        this.gjQuery = false
      }else{
        this.gjQuery = true
      }
    },

    // 高级筛选收起
    gjShou() {
      this.gjQuery = false
    },

    // 表单重置
    reset() {
      this.checkedLine = [];
      this.checkAll = [];
      this.radioP = "";
      this.dateP = [];
      this.radioF = "";
      this.dateF = [];
      this.desc = "";
      this.punObject = [];
      this.punOther = [];
      this.punName = "";
      this.punMoneyStart = "";
      this.punMoneyEnd = "";
      this.punJyType = "";
      this.punReason = [];
      this.zhutiFlag = false;
      this.shiyouFlag = false;
      this.punTags.map((item) => {
        item.checkedLine = [];
      });
      this.cityCheckAll1 = false;
      this.cities1.map(item => {
        for (let key in item) {
          item[key].map(item2 => {
            item2.checked = false;
          })
        }
      })
      for (let key2 in this.cityStatus1) {
        this.cityStatus1[key2] = false;
      }
      this.cityCheckAll = false;
      this.cities.map(item => {
        for (let key in item) {
          item[key].map(item2 => {
            item2.checked = false;
          })
        }
      })
      for (let key2 in this.cityStatus) {
        this.cityStatus[key2] = false;
      }
    },
    // 事由状态修改事件
    shiyouFlagchange(status) {
      this.shiyouFlag = status;
    },
    // 城市选择事件
    cityCheckAllChange1(val) {
      if (val) {
        this.cities1.map(item => {
          for (let key in item) {
            item[key].map(item2 => {
              item2.checked = true;
            })
          }
        })
        for (let key in this.cityStatus1) {
          this.cityStatus1[key] = true;
        }
      } else {
        this.cities1.map(item => {
          for (let key in item) {
            item[key].map(item2 => {
              item2.checked = false;
            })
          }
        })
        for (let key in this.cityStatus1) {
          this.cityStatus1[key] = false;
        }
      }
      this.cities1 = [...this.cities1]
    },
    firstCitiesChange1(status, value) {
      let tempAllStatus = true;
      if (status) {
        this.cities1.map(item => {
          for (let key in item) {
            if (key == value) {
              item[key].map(item2 => {
                item2.checked = true;
              })
            }
          }
        })
      } else {
        this.cities1.map(item => {
          for (let key in item) {
            if (key == value) {
              item[key].map(item2 => {
                item2.checked = false;
              })
            }
          }
        })
      }
      this.cities1 = [...this.cities1]
      for (let key in this.cityStatus1) {
        if (!this.cityStatus1[key]) {
          tempAllStatus = false
        }
      }
      this.cityCheckAll1 = tempAllStatus;
    },
    secondCitiesChange1(status, value, parent) {
      let tempAllStatus = true;
      if (status) {
        let tempStatus = true;
        this.cities1.map(item => {
          for (let key in item) {
            if (key == parent) {
              item[key].map(item2 => {
                if (!item2.checked || item2.checked == false) {
                  tempStatus = false;
                }
              })
            }
          }
        })
        this.cityStatus1[parent] = tempStatus;
      } else {
        this.cityStatus1[parent] = false;
      }
      for (let key in this.cityStatus1) {
        if (!this.cityStatus1[key]) {
          tempAllStatus = false
        }
      }
      this.cityCheckAll1 = tempAllStatus;
      this.cities1 = [...this.cities1];
      this.cityStatus1 = {...this.cityStatus1}
    },

    // 城市数据初始化设置
    filterCityData1(city) {
      let result = [];
      city.map(item => {
        for (let key in item) {
          item[key].map(item2 => {
            if (item2.checked) {
              result.push(item2.id)
            }
          })
        }
      })
      return result;
    },
    // 显示二级城市选择
    showMoreReason(current) {
      if (this.whichCity1 && this.whichCity1 ==current) {
        this.whichCity1 = ''
      } else {
        this.whichCity1 = current;
      }
    },

    // 城市选择事件
    cityCheckAllChange(val) {
      if (val) {
        this.cities.map(item => {
          for (let key in item) {
            item[key].map(item2 => {
              item2.checked = true;
            })
          }
        })
        for (let key in this.cityStatus) {
          this.cityStatus[key] = true;
        }
      } else {
        this.cities.map(item => {
          for (let key in item) {
            item[key].map(item2 => {
              item2.checked = false;
            })
          }
        })
        for (let key in this.cityStatus) {
          this.cityStatus[key] = false;
        }
      }
      this.cities = [...this.cities]
    },
    firstCitiesChange(status, value) {
      let tempAllStatus = true;
      if (status) {
        this.cities.map(item => {
          for (let key in item) {
            if (key == value) {
              item[key].map(item2 => {
                item2.checked = true;
              })
            }
          }
        })
      } else {
        this.cities.map(item => {
          for (let key in item) {
            if (key == value) {
              item[key].map(item2 => {
                item2.checked = false;
              })
            }
          }
        })
      }
      this.cities = [...this.cities]
      for (let key in this.cityStatus) {
        if (!this.cityStatus[key]) {
          tempAllStatus = false
        }
      }
      this.cityCheckAll = tempAllStatus;
    },
    secondCitiesChange(status, value, parent) {
      let tempAllStatus = true;
      if (status) {
        let tempStatus = true;
        this.cities.map(item => {
          for (let key in item) {
            if (key == parent) {
              item[key].map(item2 => {
                if (!item2.checked || item2.checked == false) {
                  tempStatus = false;
                }
              })
            }
          }
        })
        this.cityStatus[parent] = tempStatus;
      } else {
        this.cityStatus[parent] = false;
      }
      for (let key in this.cityStatus) {
        if (!this.cityStatus[key]) {
          tempAllStatus = false
        }
      }
      this.cityCheckAll = tempAllStatus;
      this.cities = [...this.cities];
      this.cityStatus = {...this.cityStatus}
    },
    // 显示二级城市选择
    showMoreCitys(current) {
      if (this.whichCity && this.whichCity ==current) {
        this.whichCity = ''
      } else {
        this.whichCity = current;
      }
    },
    // 城市数据初始化设置
    filterCityData(city) {
      let result = [];
      city.map(item => {
        for (let key in item) {
          item[key].map(item2 => {
            if (item2.checked) {
              result.push(item2.id)
            }
          })
        }
      })
      return result;
    },

    handleCommand() {
      this.$router.push({
        path: "punish/charts"
      });
    }

  },
};
</script>
<style scoped>
@import "../../../static/css/headerBanner.css";
@import "../../../static/css/page.css";
.el-select {
  width: 320px;
}


.el-input-coler.el-input /deep/ .el-input__inner {
    color: #2696FF;
  }


.exp-button {
  background-color: #2696FFFF;
  color: #FFFFFF;
  font-size: 14px;
  height: 32px;
  width: 80px;
  margin-left: 10px;
  padding-top: 0px;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid #2696FF;
}

.el-button--primary {
  background-color: transparent !important;
  color: #2696FF !important;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  margin-left: 10px;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid #2696FF;
  display: flex;
  align-items: center;
}

.main_background_p {
  background: url("../../../assets/image/punish/punish.png") no-repeat;
  background-size: 100%;
  width: 100%;
  height: 1.125rem ;
  overflow: hidden;
  text-align: left;
}

.main_background_p> p,.main_background> div{
  width: 79%;
  margin: 0 auto;
}
.main_background_p > #punish_p {
  margin-top: 48px;
  color: rgba(8, 40, 105, 1);
  font-size: 28px;
  font-weight: 900;
}
.main_background_p > #punish_p + p {
  color: rgba(8, 40, 105, 1);
  font-size: 16px;
  margin: 14px auto;
}

.main_background {
  width: 99.65%;
  background-color: #E2F0FF;
  min-height: 280px;
  margin-left: 3px;
  height: auto ;
  overflow: hidden;
  text-align: left;
}

.el-form-item{
  margin-bottom: 0px;
}
.main_background> p,.main_background> div{
  width: 100%;
  margin: 0 auto;
}

.main_background > #punish_p + p {
  color: rgba(8, 40, 105, 1);
  font-size: 16px;
  margin: 14px auto;
}

</style>
<style lang="scss" >
  .senior_page {
    .el-radio {
      color: #606266;
      font-weight: 500;
      cursor: pointer;
      margin-right: 0.05rem;
    }
    .el-range-editor.el-input__inner {
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.001625rem 0.052083rem;
    }

  }
  .el-tooltip__popper {
    max-width: 30%!important;
  }
  .text-ellipsis-2 {
    display: -webkit-box;
    overflow: hidden;
    white-space: normal !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
.punish_senior_detail{
  .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: auto;

  }
}
.el-main {
  background: #f3f5f7 !important;
}
.punish_select {
  margin: 33px auto;
}

.punish_background {
  width: 1248px;
  margin: 0 auto;
}
.punish_content {
  display: flex;
  padding-top: 30px;
  justify-content: space-between;
  position: absolute;
  z-index: 0;
  left: 1.78rem;
  top: 274px;
}
.punish_content > div {
  width: 384px;
  margin: 24px;
  background: white;
}
.punish_content_header {
  height: 56px;
  background-image: url("../../../assets/image/dutyList/chufa1.jpg");
  line-height: 56px;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: PingFangSC-Semibold;
  padding-left: 21px;
}
.punish_content_body {
  // width: 384px;
  margin-left: 53px;
   cursor: pointer;
}
/* 左侧数字 */
.punish_content_body > p {
  display: inline-block;
  color: rgba(36, 104, 242, 1);
  font-size: 16px;
  line-height: 30px;
  margin: 0 24px;
}
/* 右侧内容 */
.punish_content_body > div {
  display: inline-block;
  width: 306px;
  padding-bottom: 5px;
  border-bottom: 1px solid #f3f5f7;
}
.punish_content_body > div > p {
  margin-top: 15px;
  font-size: 16px;
  text-align: left;
  text-indent: -33px;
}
.punish_content_body > div > p:hover{
  color: rgba(36, 104, 242, 1);
}
.punish_content_body > div > p > span {
  color: rgba(36, 104, 242, 1);
  margin-right: 20px;
}
.punish_content_body > div > div > img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 10px;
}
.punish_content_body > div > div > p {
  display: inline-block;
  color: rgba(144, 147, 153, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  margin-top: 0px;
  line-height: 20px;
}
.punish_content_footer {
  width: 358px;
  text-align: right;
  margin-top: 8px;
  margin-bottom: 14px;
  color: rgba(24, 144, 255, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
}
.punish_content_footer>span{
  cursor: pointer;
}
.el-radio-group{
  border-radius: 0px;
  padding-right: 100px;
}
.el-dialog__header {
  text-align: left;
  font-size: 16px;
  font-weight: 900;
  color: #262626;
}
.el-input__inner {
  height: 0.16666666rem;
}
.punish_senior_detail {
  height: 2.4896rem;
  width: 9.3598rem;
  left: 1.78rem;
  top: 274px;
  background-color: white;
  margin: 0.125rem auto 0;
  box-shadow: 0 0 0.052083rem 0 rgb(0 0 0 / 10%);
}
.punish_senior_detail{
  .punish_detail_header {
    margin-left: 15px;
    padding-top: 6px;
    display: flex;
  }
  .punish_detail_header > img {
    width: 44px;
    height: 44px;
    margin: 4px 12px 0 0;
  }
  .punish_detail_header > span {
    color: rgba(0, 0, 0, 1);
    font-weight: 900;
    font-size: 20px;
    font-family: PingFangSC-Semibold;
    line-height: 51px;
  }
}
  .el-table {
    ::v-deep .warning-row {
      background: #FAFAFA;
    }
}

  .senior_punish_detail_box{
  height: 100%;
}
  .el-table__empty-block {
    min-height: 2.79166667rem;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .el-dialog__title {
    font-size: 16px;
    margin-left: 10px;
    font-weight: bold;
    color: #000000;
  }
  .el-dialog__header {
    padding-top: 16px;
    padding-bottom: 16px;
  }
/*  .el-table {
    .el-table__fixed {
      height:auto !important;
      bottom:22px !important;
    }
  }*/
</style>